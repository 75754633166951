<template>
    <Popup :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
      <div  slot="component" style="padding: 1rem;">
          <h2 v-if="currentTextStatus">{{isSubscribeRentStatus}}</h2>
          <h3>{{$t("And Watch this Content")}}</h3>
          <button  class="button-primary" @click="actActiveSubscribePopup">
                {{ $t("SUBSCRIBE NOW") }}
            </button> 
            <p class="or-purchase" v-if="currentTextStatus"> <span>{{$t("OR" + " " + currentTextStatus[1])}}</span></p>
            <p v-if="rentNowPriceModel && rentNowPriceModel.amount"> {{ rentNowPriceModel.currency  }} {{rentNowPriceModel.amount / 100}}</p>
            <button v-if="currentTextStatus" class="button-primary purchase-now" @click="actActivePurchaseAction">
                {{ $t(currentTextStatus[1] + " " + "NOW") }}
            </button>

       </div>
    </Popup>
  </template>
  
  <script>
  import { eventBus } from "@/eventBus";
  import { mapGetters, mapActions } from "vuex";
  import { _providerId } from "@/provider-config.js";
  import Utility from "@/mixins/Utility.js";

  export default {
    props: ['availPlanListPopup', 'contentDetails', 'buttonStatus'],
    data() {
      return {
        showLoader: true,
        width: "40%",
        margin: "5% auto",
        position: "relative",
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        radiusType: "full",
        dummyfaqs: [], //later it will store the API JSON Data
        upArrow: require("@/assets/icons/down_arrow.svg"),
        downArrow: require("@/assets/icons/up_arrow.svg"),
        providerUniqueId: _providerId,
        localDisplayLang: null,
        rentNowPriceModel: '',
        userInfoDetails: [],
        isSubscribeRentStatus:""
      };
    },
    computed: {
      ...mapGetters(["appConfig", "subscriberid", "getRtl"])
    },
    mounted () {
      try {
      this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];
    } catch(err) {
      console.log(err)
    }
      this.actActivePurchasePopup();
      this.currentTextStatus = this.buttonStatus == 'SUBSCRIBE OR RENT' ? ['Rent', 'RENT'] : ['Purchase', 'PURCHASE']
      this.isSubscribeRentStatus = this.$t("Subscribe" + "/" + this.currentTextStatus[0]);

    },
    methods: {
      ...mapActions(["availabilityDetail"]),
      actActivePurchaseAction () {
        this.$emit("closepopup", false)

        eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);

      },
      closePopup (state) {
        this.$emit("closepopup", state)
      },
      actActivePurchasePopup () {
        let availablePlan = "";

        for (let item of this.availPlanListPopup.filteredAvailabilities) {
          if (item.pricemodel == 'PAID' || item.pricemodel == 'RENTAL') {
            availablePlan = item.availabilityid;
          }
        }

        this.availabilityDetail(availablePlan).then((res) => {
          let userCountryCurrency = this.userInfoDetails.subscribercountry == 'IN' ? 'INR' : 'USD';
              let currentPriceItem = {};
                for (let Item of res.data.priceclassdetail)  {
                    if (Item.currency == userCountryCurrency) {
                      currentPriceItem = Item;
                    }
                }
              this.rentNowPriceModel = {};
              this.rentNowPriceModel.planname = this.contentDetails.title;
              this.rentNowPriceModel.amount = currentPriceItem.price;
              this.rentNowPriceModel.planid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.priceclassid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.availabilityid = availablePlan;
              this.rentNowPriceModel.planinterval = res.data.licenseduration;
              this.rentNowPriceModel.currency = currentPriceItem.currency;
              this.rentNowPriceModel.rental = "PURCHASE";
              // this.rentNowPriceModel.getPoster = this.getPoster();
              this.rentNowPriceModel.objectid  = this.contentDetails.objectid;

            })


      },
      actActiveSubscribePopup () {
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
      }
    },
    components: {
      Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue")
    },
    mixins: [Utility],

  };
  </script>
  
  <style lang="scss" scoped>
    @import "@/sass/_variables.scss";

      h2,h3,p {
        color: $detail-page-main-content-tittle;
        opacity: 1;
        font-family: $font-regular;
        text-align: center;
        margin: 1rem;
      }
      button {
        margin: 1.5rem auto;
        display: flex;
        justify-content: center;
        width: 13rem;
      }
      .or-purchase {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid gray; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
      }
      .or-purchase span {
        background: gray; 
      }
  </style>
  